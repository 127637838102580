import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Avatar from "@material-ui/core/Avatar";

import iconImage from "../../images/icon.png";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import {hashHistory} from 'react-router'

import * as Scroll from 'react-scroll';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    appBar: {
        position: "fixed",
        backgroundColor: 'white',
    },
    toolbar: {
        marginLeft: 20
    },
    title: {
        marginLeft: 20
    },
    menu: {
        display: "grid",
        gridTemplateColumns: "repeat(4,auto)",
        gridColumnGap: theme.spacing(3),
        marginRight: 50
    }
}));


export default function TopBar(props) {
    const classes = useStyles();

    const [anchorServerEl, setAnchorServerEl] = React.useState(null);
    const [anchorCaseEl, setAnchorCaseEl] = React.useState(null);
    const [anchorCompanyEl, setAnchorCompanyEl] = React.useState(null);

    function handleClick(event, type) {
        switch (type) {
            case "index": {
                if (hashHistory.getCurrentLocation().pathname !== "/") {
                    cleanScroll();
                }

                hashHistory.push("/");
                break;
            }
            case "server": {
                setAnchorServerEl(event.currentTarget);
                break;
            }
            case "case": {
                setAnchorCaseEl(event.currentTarget);
                break;
            }
            case "company": {
                setAnchorCompanyEl(event.currentTarget);
                break;
            }
        }

    }

    function handleClose(type, menuName) {
        switch (type) {
            case "server": {
                setAnchorServerEl(null);
                break;
            }
            case "case": {
                setAnchorCaseEl(null);
                break;
            }
            case "company": {
                setAnchorCompanyEl(null);
                break;
            }
        }

        switch (menuName) {
            case "3D角色建模": {
                goToIndexRouter(() => {
                    scrollToAnchor("3D角色建模")
                });
                break;
            }
            case "3D场景建模": {
                goToIndexRouter(() => {
                    scrollToAnchor("3D场景建模")
                });
                break;
            }
            case "H5游戏开发": {
                goToIndexRouter(() => {
                    scrollToAnchor("H5游戏开发")
                });
                break;
            }
            case "跨平台移动App": {
                goToIndexRouter(() => {
                    scrollToAnchor("跨平台移动App")
                });
                break;
            }
            case "3D案例": {
                goToIndexRouter(() => {
                    scrollToAnchor("3D案例")
                });
                break;
            }
            case "软件案例": {
                goToIndexRouter(() => {
                    scrollToAnchor("软件案例")
                });
                break;
            }
            case "aboutus": {
                cleanScroll();
                hashHistory.push("/aboutus");
                break;
            }
            case "joinus": {
                cleanScroll();
                hashHistory.push("/joinus");
                break;
            }
        }
    }

    function scrollToAnchor(anchorName) {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                let scrollHeight = anchorElement.offsetTop - 65;
                Scroll.animateScroll.scrollTo(scrollHeight);
            }
        }
    }

    function goToIndexRouter(complete: Function) {
        if (hashHistory.getCurrentLocation().pathname !== "/") {
            hashHistory.push("/");
            let timer = setTimeout(() => {
                complete();
                clearTimeout(timer);
            }, 500);
        } else {
            complete();
        }
    }

    function cleanScroll() {
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Avatar alt="icon" src={iconImage}></Avatar>
                    <Typography className={classes.title} variant="h6" color="primary">
                        <Box letterSpacing={2}>鹰能网络</Box>
                    </Typography>
                    <div className={classes.grow}/>
                    <Typography className={classes.menu}>
                        <Button color="primary" onClick={(e) => handleClick(e, "index")}>首页</Button>
                        <Button color="primary" onClick={(e) => handleClick(e, "server")}>服务</Button>
                        <Button color="primary" onClick={(e) => handleClick(e, "case")}>案例</Button>
                        <Button color="primary" onClick={(e) => handleClick(e, "company")}>公司</Button>
                    </Typography>
                    <Menu anchorEl={anchorServerEl} keepMounted open={Boolean(anchorServerEl)} onClose={() => handleClose("server")}>
                        <MenuItem onClick={() => handleClose("server", "3D角色建模")}>3D角色建模</MenuItem>
                        <MenuItem onClick={() => handleClose("server", "3D场景建模")}>3D场景建模</MenuItem>
                        <MenuItem onClick={() => handleClose("server", "H5游戏开发")}>H5游戏开发</MenuItem>
                        <MenuItem onClick={() => handleClose("server", "跨平台移动App")}>跨平台移动App</MenuItem>
                    </Menu>
                    <Menu anchorEl={anchorCaseEl} keepMounted open={Boolean(anchorCaseEl)} onClose={() => handleClose("case")}>
                        <MenuItem onClick={() => handleClose("case", "3D案例")}>3D案例</MenuItem>
                        <MenuItem onClick={() => handleClose("case", "软件案例")}>软件案例</MenuItem>
                    </Menu>
                    <Menu anchorEl={anchorCompanyEl} keepMounted open={Boolean(anchorCompanyEl)} onClose={() => handleClose("company")}>
                        <MenuItem onClick={() => handleClose("company", "aboutus")}>关于我们</MenuItem>
                        <MenuItem onClick={() => handleClose("company", "joinus")}>加入我们</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </div>
    );
}