import React from 'react';
import {grey} from "@material-ui/core/colors";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import "./App.css"
import TopBar from "./views/topbar/TopBar";
import BottomBar from "./views/bottombar/BottomBar";
import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {main: grey[900]}
    },
});

const useStyles = makeStyles(theme => ({
    app: {
        minHeight: "100%",
    },
    content: {
        marginTop: 65,
        minHeight: "calc(100vh - 235px)",
    },
    bottomBar: {
        marginTop: 30,
    }

}));

export default function App(props) {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.app}>
                <TopBar/>
                <CSSTransitionGroup
                    transitionName="content"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    <div key={props.location.pathname} className={classes.content}>
                        {props.children}
                    </div>
                </CSSTransitionGroup>
                <BottomBar className={classes.bottomBar}/>
            </div>
        </ThemeProvider>
    );
}
