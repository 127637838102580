import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    content: {
        display: "grid",
        gridTemplateRows: "100px auto auto auto 150px",
        gridTemplateColumns: "50px auto 50px",
        gridRowGap: theme.spacing(10),
        justifyItems: "left",
        alignItems: "center",
    },
    title: {
        gridRow: "1/span 1",
        gridColumn: "2/span 1",
        justifySelf: "center",
    },
    row: {
        gridColumn: "2/span 1",
    }
}));

/**
 * 加入我们
 */
export default function JoinUs() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Typography className={classes.title} variant="h4">加入我们</Typography>
            <div className={classes.row}>
                <Typography variant="h6"><Box fontWeight="bold">次世代实习生</Box></Typography>
                <Typography><Box fontWeight="bold">岗位职责</Box></Typography>
                <Typography>
                    <ol>
                        <li>完成游戏里面角色/场景及周边高模雕刻和贴图绘制；</li>
                    </ol>
                </Typography>
                <Typography><Box fontWeight="bold">职位要求</Box></Typography>
                <Typography>
                    <ol>
                        <li>热爱游戏，希望职业规划往游戏美术次世代角色方向发展；</li>
                        <li>有一定的美术基础和ZBrush、3Dmax、ps等软件基础；</li>
                        <li>数字媒体、动画、游戏设计等美术相关专业优先；</li>
                    </ol>
                </Typography>
            </div>
            <div className={classes.row}>
                <Typography variant="h6"><Box fontWeight="bold">H5白鹭游戏实习生</Box></Typography>
                <Typography><Box fontWeight="bold">岗位职责</Box></Typography>
                <Typography>
                    <ol>
                        <li>基于egret或laya引擎进行H5手游开发；</li>
                        <li>参与需求分析和框架设计；</li>
                        <li>实现游戏功能；</li>
                    </ol>
                </Typography>
                <Typography><Box fontWeight="bold">职位要求</Box></Typography>
                <Typography>
                    <ol>
                        <li>计算机相关专业；</li>
                        <li>学习能力强，热爱游戏行业，有游戏相关实习经验优先考虑；</li>
                        <li>熟悉 JavaScript/TypeScript语言；</li>
                        <li>熟悉websocket和http协议；</li>
                        <li>有良好的编码习惯，擅于思考，能独立排查问题和解决问题；</li>
                        <li>工作积极，有良好的团队合作能力，有较强的抗压能力；</li>
                    </ol>
                </Typography>
            </div>
            <div className={classes.row}>
                <Typography variant="h6"><Box fontWeight="bold">PHP实习生</Box></Typography>
                <Typography><Box fontWeight="bold">岗位职责</Box></Typography>
                <Typography>
                    <ol>
                        <li>熟悉使用PHP语言，了解面向对象，面向过程的编程，熟悉至少一种框架者优先；</li>
                        <li>熟悉web安全相关知识，能够对诸如xss，csrf，命令注入等漏洞做出防御；</li>
                        <li>熟练Linux，能进行基本的Linux命令；</li>
                        <li>有耐心，较强的沟通能力，高度的责任心；</li>
                    </ol>
                </Typography>
                <Typography><Box fontWeight="bold">职位要求</Box></Typography>
                <Typography>
                    <ol>
                        <li>计算机相关专业；</li>
                        <li>学习能力强，热爱游戏行业，有游戏相关实习经验优先考虑；</li>
                        <li>有良好的编码习惯，擅于思考，能独立排查问题和解决问题；</li>
                        <li>工作积极，有良好的团队合作能力，有较强的抗压能力；</li>
                    </ol>
                </Typography>
            </div>
        </div>
    );
};