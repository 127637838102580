import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

import case3DRole1Image from "../../images/case3DRole1.jpg";
import case3DRole2Image from "../../images/case3DRole2.jpg";
import case3DRole3Image from "../../images/case3DRole3.jpg";
import case3DRole4Image from "../../images/case3DRole4.jpg";

const useStyles = makeStyles(theme => ({
    content: {
        display: "grid",
        gridTemplateRows: "auto auto",
        gridTemplateColumns: "auto auto auto auto",
        gridRowGap: theme.spacing(2),
        justifyItems: "center",
        alignItems: "center",
    },
    case3DRole1: {
        gridRow: "1/span 1",
        gridColumn: "2/span 1",
    },
    case3DRole2: {
        gridRow: "1/span 1",
        gridColumn: "3/span 1",
    },
    case3DRole3: {
        gridRow: "2/span 1",
        gridColumn: "2/span 1",
    },
    case3DRole4: {
        gridRow: "2/span 1",
        gridColumn: "3/span 1",
    },
}));

/**
 * 3D角色案例
 */

export default function Case3DRole() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <div className={classes.case3DRole1}><img src={case3DRole1Image} alt="3D案例"></img></div>
            <div className={classes.case3DRole2}><img src={case3DRole2Image} alt="3D案例"></img></div>
            <div className={classes.case3DRole3}><img src={case3DRole3Image} alt="3D案例"></img></div>
            <div className={classes.case3DRole4}><img src={case3DRole4Image} alt="3D案例"></img></div>
        </div>
    );
};