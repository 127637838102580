import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

import caseSoft1Image from "../../images/caseSoft1.jpg";
import caseSoft2Image from "../../images/caseSoft2.jpg";
import caseSoft3Image from "../../images/caseSoft3.jpg";
import caseSoft4Image from "../../images/caseSoft4.jpg";

const useStyles = makeStyles(theme => ({
    content: {
        display: "grid",
        gridTemplateRows: "auto auto",
        gridTemplateColumns: "auto auto auto auto",
        gridRowGap: theme.spacing(2),
        justifyItems: "center",
        alignItems: "center",
    },
    caseSoft1: {
        gridRow: "1/span 1",
        gridColumn: "2/span 1",
    },
    caseSoft2: {
        gridRow: "1/span 1",
        gridColumn: "3/span 1",
    },
    caseSoft3: {
        gridRow: "2/span 1",
        gridColumn: "2/span 1",
    },
    caseSoft4: {
        gridRow: "2/span 1",
        gridColumn: "3/span 1",
    },
}));

/**
 * 软件案例
 */
export default function CaseSoft() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <div className={classes.caseSoft1}><img src={caseSoft1Image} alt="软件案例"></img></div>
            <div className={classes.caseSoft2}><img src={caseSoft2Image} alt="软件案例"></img></div>
            <div className={classes.caseSoft3}><img src={caseSoft3Image} alt="软件案例"></img></div>
            <div className={classes.caseSoft4}><img src={caseSoft4Image} alt="软件案例"></img></div>
        </div>
    );
};