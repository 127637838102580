import React from 'react';
import {hashHistory, Route, Router} from "react-router";
import App from "../App";
import Home from "../views/home/Home";
import IndexRoute from "react-router/es/IndexRoute";
import Case3DRole from "../views/case3drole/Case3DRole";
import CaseSoft from "../views/casesoft/CaseSoft";
import AboutUs from "../views/aboutus/AboutUs";
import JoinUs from "../views/joinus/JoinUs";

export default class MyRouter extends React.Component {
    render() {
        return (
            <Router history={hashHistory}>
                <Route path="/" component={App}>
                    <IndexRoute component={Home}/>
                    <Route path="case3drole" component={Case3DRole}/>
                    <Route path="casesoft" component={CaseSoft}/>
                    <Route path="aboutus" component={AboutUs}/>
                    <Route path="joinus" component={JoinUs}/>
                </Route>
            </Router>
        );
    }
}