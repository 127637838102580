import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

import clsx from "clsx";

import Typography from "@material-ui/core/Typography";

import bottomBgImage from "../../images/bottombg.jpg";
import qrImage from "../../images/qr.jpg";
import Link from "@material-ui/core/Link";
import {hashHistory} from "react-router";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    bottomBar: {
        // position: "relative",
    },
    content: {
        // position: "absolute",
        // left: 0,
        // top: 0,

        display: "grid",
        gridTemplateColumns: "80px repeat(6,auto)",
        gridTemplateRows: "80px auto",

        // color: "#FFFFFF",
        width: "100%"
    },
    divider: {
        width: "90%",
        margin: "0px auto"
    },
    company: {
        marginTop: 20,
        gridColumn: "2/span 6",
        gridRow: "1/span 1",
    },
    aboutUS: {
        gridColumn: "2/span 1",
        gridRow: "2/span 1",
        cursor: "pointer",
    },
    // contactUS: {
    //     gridColumn: "3/span 1",
    //     gridRow: "2/span 1",
    // },
    joinUS: {
        gridColumn: "3/span 1",
        gridRow: "2/span 1",
        cursor: "pointer",
    },
    email: {
        gridColumn: "4/span 1",
        gridRow: "2/span 1",
    },
    phone: {
        gridColumn: "5/span 1",
        gridRow: "2/span 1",
    },
    qr: {
        marginTop: 20,
        gridColumn: "7/span 1",
        gridRow: "1/span 2",
    },

    cip:{
        textAlign:"center",
    }
}));

export default function BottomBar(props) {
    const classes = useStyles();

    function goto(url) {
        window.scrollTo(0, 0);
        hashHistory.push(url);
    }

    return (
        <div className={clsx(props.className, classes.bottomBar)}>
            {/*<div><img alt="背景" src={bottomBgImage}/></div>*/}
            <Divider className={classes.divider}/>
            <div className={classes.content}>
                <Typography className={classes.company} variant="h6">四川鹰能网络科技有限公司</Typography>
                <Typography className={classes.aboutUS}><Link color="inherit" onClick={() => goto("aboutus")}>关于我们</Link></Typography>
                {/*<Typography className={classes.contactUS}><Link color="inherit" href="/">联系我们</Link></Typography>*/}
                <Typography className={classes.joinUS}><Link color="inherit" onClick={() => goto("joinus")}>加入我们</Link></Typography>
                <Typography className={classes.email}>邮箱:office@scyingneng.com</Typography>
                <Typography className={classes.phone}>联系方式:19141000935</Typography>
                <div className={classes.qr}><img alt="二维码" src={qrImage}/></div>
            </div>
            <div className={classes.cip}><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021032025号-1</a></div>
        </div>
    );
}