import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    content: {
        display: "grid",
        gridTemplateRows: "100px 150px 150px",
        gridTemplateColumns: "50px auto 50px",
        gridRowGap: theme.spacing(1),
        justifyItems: "center",
        alignItems: "center",
    },
    title: {
        gridRow: "1/span 1",
        gridColumn: "2/span 1"
    },
    row1: {
        gridColumn: "2/span 1",
        textIndent: "2rem",
    }
}));

/**
 * 关于我们
 */
export default function AboutUs() {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Typography className={classes.title} variant="h4">四川鹰能网络科技有限公司简介</Typography>
            <Typography className={classes.row1}>
                <Box letterSpacing={2} lineHeight={2}>
                    四川鹰能网络科技有限公司成立于2019年，是软件与信息技术外包一体的高新软件技术及互联网服务公司！我们致力于为客户提供IT人员外包、
                    软件开发、信息技术咨询服务、教育咨询服务、3D游戏建模制作服务。我们以专业的技术及资深技术团队人员，协助我们的客户创造价值，不断成长与发展。
                </Box>
            </Typography>
            <Typography className={classes.row1}>
                <Box letterSpacing={2} lineHeight={2}>
                    自创建以来公司非常重视企业多样化发展管理工作，3D美术次时代角色、场景制作及软件研发、技术支持是公司两大核心部门，
                    现已建立一套比较完整的管理体制下团队核心人员参与《无冬之夜》、《冠军在线》、《PX》、《TOCH》《圣斗士》及《盗墓笔记》等大型端游及手机游戏美术制作，
                    程序部核心人员参与开发软件定制在线销售系统、微商城、开心农场游戏、星际手游等制作，获得丰富的实战项目经历及多样化技术发展优势。全方位满足企业与客户信息化开发、
                    输送人才需求得到一致好评认可，成为客户长期选择稳定的合作伙伴。
                </Box>
            </Typography>
        </div>
    );
};