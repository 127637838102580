import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import bannerImage from "../../images/banner.jpg";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import advantage1Image from "../../images/advantage1.png";
import advantage2Image from "../../images/advantage2.png";
import advantage3Image from "../../images/advantage3.png";
import advantage4Image from "../../images/advantage4.png";

import case1Image from "../../images/case1.jpg";
import case2Image from "../../images/case2.jpg";
import case3Image from "../../images/case3.jpg";

import softCase1Image from "../../images/softcase1.jpg";
import softCase2Image from "../../images/softcase2.jpg";
import softCase3Image from "../../images/softcase3.jpg";
import Box from "@material-ui/core/Box";

import {hashHistory} from 'react-router'

const useStyles = makeStyles(theme => ({
    home: {
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: "auto auto auto auto auto auto auto",
        justifyItems: "center",
        gridRowGap: theme.spacing(2)
    },
    banner: {
        gridColumn: "1/span 1",
    },
    advantageTitle: {
        color: "#26a8e0",
        marginTop: "30px"
    },
    advantageDivider: {
        width: "30%"
    },
    button: {
        backgroundColor: "#0069d9",

        '&:hover': {
            backgroundColor: '#0062cc',
            borderColor: '#0062cc',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    },

    advantage1: {
        display: "grid",
        gridTemplateRows: "150px 107px auto",
        gridTemplateColumns: "50px auto auto 50px",

        width: "100%",
        height: 321,
        backgroundColor: "#d0d3d5"
    },
    advantage1_title: {
        gridColumn: "2/span 1",
        gridRow: "1/span 1",
        marginTop: 50,
    },
    advantage1_content: {
        gridColumn: "2/span 1",
        gridRow: "2/span 1",
    },
    advantage1_btn: {
        justifySelf: "start",
        alignSelf: "start",

        gridColumn: "2/span 1",
        gridRow: "3/span 1",
    },
    advantage1_image: {
        gridColumn: "3/span 1",
        gridRow: "1/span 1",
    },
    advantage2: {
        display: "grid",
        gridTemplateRows: "150px 107px auto",
        gridTemplateColumns: "50px auto 80px auto 50px",

        width: "100%",
        height: 360,
        marginTop: 50
    },
    advantage2_title: {
        gridColumn: "4/span 1",
        gridRow: "1/span 1",
        marginTop: 50,
    },
    advantage2_content: {
        gridColumn: "4/span 1",
        gridRow: "2/span 1",
    },
    advantage2_btn: {
        justifySelf: "start",
        alignSelf: "start",

        gridColumn: "4/span 1",
        gridRow: "3/span 1",
    },
    advantage2_image: {
        gridColumn: "2/span 1",
        gridRow: "1/span 1",
    },

    case: {
        display: "grid",
        gridTemplateRows: "auto",
        gridTemplateColumns: "62px auto auto auto auto auto 62px",

        width: "100%",
    },
    case1_image: {
        gridColumn: "2/span 1",
    },
    case2_image: {
        gridColumn: "4/span 1",
    },
    case3_image: {
        gridColumn: "6/span 1",
    },

    bottomBar: {
        marginTop: 30,
    }
}));

export default function Home(props) {
    const classes = useStyles();

    function goto(url) {
        window.scrollTo(0,0);
        hashHistory.push(url);
    }

    return (
        <div className={classes.home}>
            <div className={classes.banner}><img alt="banner" src={bannerImage}></img></div>

            <Typography className={classes.advantageTitle} variant="h4">
                <Box letterSpacing={10}>我们的优势</Box>
            </Typography>
            <Divider className={classes.advantageDivider}/>
            <div id="3D角色建模" className={classes.advantage1}>
                <Typography className={classes.advantage1_title} variant="h4" color="primary">3D角色建模</Typography>
                <Typography className={classes.advantage1_content} color="primary">丰富次时代建模实战经验，高度还原原画设计 贴图基于物理渲染引擎方式制作，方便导入Unity3D、Ue4引擎</Typography>
                <Button className={clsx(classes.button, classes.advantage1_btn)} variant="contained" color="primary"
                        onClick={() => goto("case3drole")}>更多</Button>
                <div className={classes.advantage1_image}><img alt="3D角色建模" src={advantage1Image}/></div>
            </div>
            <div id="3D场景建模" className={classes.advantage2}>
                <Typography className={classes.advantage2_title} variant="h4" color="primary">3D场景建模</Typography>
                <Typography className={classes.advantage2_content} color="primary">3D场景基础物件建模，Uniy3D游戏场景基础建模 Ue4室内及室外场景基础物件建模</Typography>
                <Button className={clsx(classes.button, classes.advantage2_btn)} variant="contained" color="primary"
                        onClick={() => goto("case3drole")}>更多</Button>
                <div className={classes.advantage2_image}><img alt="3D场景建模" src={advantage2Image}/></div>
            </div>
            <div id="H5游戏开发" className={classes.advantage1}>
                <Typography className={classes.advantage1_title} variant="h4" color="primary">H5游戏开发</Typography>
                <Typography className={classes.advantage1_content} color="primary">采用主流技术制作各种类型的H5游戏、微信游戏、小游戏活动游戏等，支持跨平台发布。</Typography>
                <Button className={clsx(classes.button, classes.advantage1_btn)} variant="contained" color="primary"
                        onClick={() => goto("casesoft")}>更多</Button>
                <div className={classes.advantage1_image}><img alt="H5游戏开发" src={advantage3Image}/></div>
            </div>
            <div id="跨平台移动App" className={classes.advantage2}>
                <Typography className={classes.advantage2_title} variant="h4" color="primary">跨平台移动App</Typography>
                <Typography className={classes.advantage2_content} color="primary">采用主流Web跨平台技术，一次开发可同时发布 IOS、Android、Web运行性能接近原生态系统</Typography>
                <Button className={clsx(classes.button, classes.advantage2_btn)} variant="contained" color="primary"
                        onClick={() => goto("casesoft")}>更多</Button>
                <div className={classes.advantage2_image}><img alt="跨平台移动App" src={advantage4Image}/></div>
            </div>

            <Typography id="3D案例" className={classes.advantageTitle} variant="h4">
                <Box letterSpacing={10}>3D案例展示</Box>
            </Typography>
            <Divider className={classes.advantageDivider}/>
            <div className={classes.case}>
                <div className={classes.case1_image}><img alt="3D案例展示" src={case1Image}/></div>
                <div className={classes.case2_image}><img alt="3D案例展示" src={case2Image}/></div>
                <div className={classes.case3_image}><img alt="3D案例展示" src={case3Image}/></div>
            </div>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => goto("case3drole")}>更多案例</Button>

            <Typography id="软件案例" className={classes.advantageTitle} variant="h4">
                <Box letterSpacing={10}>软件案例展示</Box>
            </Typography>
            <Divider className={classes.advantageDivider}/>
            <div className={classes.case}>
                <div className={classes.case1_image}><img alt="软件案例展示" src={softCase1Image}/></div>
                <div className={classes.case2_image}><img alt="软件案例展示" src={softCase2Image}/></div>
                <div className={classes.case3_image}><img alt="软件案例展示" src={softCase3Image}/></div>
            </div>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => goto("casesoft")}>更多案例</Button>

        </div>
    );
}